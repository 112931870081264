import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: [], 
    refresh: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        unAssignTagtoUser: (state, action) => {
            state.user = state.user.map(user =>
                user.id === action.payload ? { ...user, tag_id: null, tag_name: null } : user
            );
        },
        setRefresh: (state, action) => {
            state.refresh = !state.refresh;
        }
    }
})

export default userSlice;
export const userActions = userSlice.actions;